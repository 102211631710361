import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader/LoaderService';
import { Broadcaster } from './app.broadcaster';
import { LoaderState } from './loader/loaderState';
import { Router } from '@angular/router';
// declare var $:any
var $ = require('jquery');
// declare var  $ : any
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Rage Fan';
  show: boolean;
  private subscription: Subscription;
  showBgClass: boolean;
  appTypeValue: any;

  // constructor() {
  constructor(private loaderService: LoaderService, private broadcaster: Broadcaster, private router: Router) {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        setTimeout(() => {
          if (!state.show) {
            this.show = state.show;
          } else {
            this.show = state.show;
          }
        });
      });
    this.broadcaster.on<boolean>('showBgClass').subscribe((response) => {
      this.showBgClass = response;
    });

    if (this.router.url != '/dashboard/home') {
      this.appTypeValue = JSON.parse(localStorage.getItem('appType'));
      if (this.appTypeValue) {
        if (this.appTypeValue == 1) {
          $('head').append('<link rel="stylesheet" href="assets/css/safe.css" type="text/css" />');
          $('meta[name="theme-color"]').remove();
          $('head').append('<meta name="theme-color" content="#2e7fae">');
        } else {
          $('link[href="assets/css/safe.css"]').remove();
          $('meta[name="theme-color"]').remove();
          $('head').append('<meta name="theme-color" content="#AF1000">');
        }
      }
    }


  }

  
  removeHeaderClass() {

    // const exist = $('.not-found');
    if (this.router.url.indexOf('webview') !== -1) {
      return 'no-footer';
    } else if (this.router.url.indexOf('dashboard') !== -1 || this.router.url.indexOf('header') !== -1
      || this.router.url.indexOf('contest-code') !== -1 || this.router.url.indexOf('support') !== -1
      || this.router.url.indexOf('transaction-history') !== -1 || this.router.url.indexOf('add-cash') !== -1
      || this.router.url.indexOf('change-password') !== -1 || this.router.url.indexOf('kyc') !== -1
      || this.router.url.indexOf('my-referral') !== -1 || this.router.url.indexOf('withdrawal') !== -1
      || this.router.url.indexOf('refer-n-earn') !== -1 || this.router.url.indexOf('user-profile') !== -1
      || this.router.url.indexOf('view-player-stats') !== -1 || this.router.url.indexOf('player-history') !== -1) {
      return '';
    } else {
      return '';
    }
  }
}

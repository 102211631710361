import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderState} from './loaderState';

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {
  }

  showPopup() {
    this.loaderSubject.next(<LoaderState>{showPopUp: true});
  }
  hidePopup() {
    this.loaderSubject.next(<LoaderState>{showPopUp: false});
  }

  show() {
    this.loaderSubject.next(<LoaderState>{show: true});
  }

  hide() {
    this.loaderSubject.next(<LoaderState>{show: false});
  }
}

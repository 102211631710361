import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Broadcaster } from './app.broadcaster';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { LoaderService } from './loader/LoaderService';
import { SocialModule } from './modules/social.module';
import { CommonService } from './services/common.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SocialModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [LoaderService, CommonService, Broadcaster],
  // providers: [Broadcaster],
  bootstrap: [AppComponent]
})
export class AppModule {
}

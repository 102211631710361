import {Injectable,} from '@angular/core';
import $ from '../../assets/js/jquery-3.3.1.min';
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular-6-social-login-v2';
import { AppConfig } from '../app.config';
import { Biconomy } from "@biconomy/mexa";
import Portis from "@portis/web3";
import  Fortmatic   from 'fortmatic';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { domainType,metaTransactionType,domainData } from '../app.config';
import {Router} from '@angular/router';
import {LoaderService} from '../loader/LoaderService';
import {Broadcaster} from '../app.broadcaster';
const abi = require("../../assets/abi/RageToken.json").abi;
import WalletConnectProvider from "@maticnetwork/walletconnect-provider"
import MEWconnect from "@myetherwallet/mewconnect-web-client"
import Web3 from 'web3';
 var web3utils = require('web3-utils');
 const sigUtil = require("eth-sig-util");

declare let window: any;

@Injectable()
export class CommonService {

  matchInfo: any;
  element: any;
  label: any;
 contractAddress ='0xb17A9A058F05573bF820601E3A6CC968bE39b495';
 // contractAddress ='0xB9621511915c80f8882066F3223b346B218b81a7';
 maticProvider =  'https://rpc-mumbai.matic.today';
  //home pape popup state manager
  firstTimePopup:boolean =true;
  gamerecipient:string ='0x728c7eb7d1d6b8f3d35e0f59e4b3434f8faf94b4';
  mybalance:any = 0;
  accountAddress:any ='Not connected with wallet';

constructor(private socialAuthService: AuthService, private httpClient: HttpClient, private router: Router,
              private loaderService: LoaderService, private broadcaster: Broadcaster) {
  }

  getUserId() {
    if (localStorage.getItem('save_token')) {
      return JSON.parse(localStorage.getItem('save_token')).UserID;
    }
  }


  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    let url;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      url = 'account/register-with-fb';
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      url = 'account/register-with-gmail';
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.loaderService.show();
        this.socialLoginIn(userData, url, socialPlatform).subscribe((response1: any) => {
          this.loaderService.hide();
          if (response1.Status) {
            if (response1.Result.MobileVerify && response1.Result.IsFirstTime) {
              localStorage.setItem('save_token', JSON.stringify(response1.Result));
              this.router.navigate(['/dashboard/home']);
            } else {
              this.checkStateVarification(response1);
            }
          }
        }, (error) => {
          this.loaderService.hide();
        });
      }
    );
  }

async transferpay(gamerecipient,payfees){
  if (
      typeof window.ethereum !== "undefined" &&
      window.ethereum.isMetaMask
  ) {
 let  provider = window["ethereum"];
      await provider.enable();
  let matictestProvider = new Web3.providers.HttpProvider(this.maticProvider);
     const biconomy = new Biconomy(matictestProvider, { apiKey: 'Mg4po8zbw.b0e298c7-8f86-4543-b9c5-64ae297ebc16', debug: true });

  const web3 = new Web3(biconomy);

  let walletWeb3 = new Web3(window.ethereum);
let   contract = await new web3.eth.Contract(
            abi, this.contractAddress
            
          );
let userAddress = provider.selectedAddress;
 // this.accountAddress = matictestProvider.accountAddress;
           // console.log(provider.selectedAddress);
let amount = web3utils.toWei(payfees.toString(), 'ether');
let nonce = await contract.methods.getNonce(userAddress).call();
   let   functionSignature =  contract.methods.approve(userAddress, amount).encodeABI();
       console.log("7", functionSignature );
   await   this.executeMetaTransaction(functionSignature, nonce, userAddress,walletWeb3,web3,contract);
     
      functionSignature = await contract.methods.transferFrom( userAddress, gamerecipient, amount).encodeABI();
      console.log("8", functionSignature );
      let nonce2 = (parseInt(nonce) + 1);
     await this.executeMetaTransaction(functionSignature, nonce2, userAddress,walletWeb3,web3,contract);




         }
       }


  executeMetaTransaction = async (functionSignature, nonce, address,walletWeb3,web3,contract) => {
    // const accounts = await web3.eth.getAccounts();
    // let userAddress = accounts[0];
    let userAddress = address;
    let message:any = { };
    message.nonce = parseInt(nonce);
    message.from = userAddress;
    message.functionSignature = functionSignature;
    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message
    });
      console.log(dataToSign);
      walletWeb3.eth.currentProvider.send(
        {
          jsonrpc: "2.0",
          id: 999999999999,
          method: "eth_signTypedData_v4",
          params: [userAddress, dataToSign]
      },
     async (error, response)=> {
          if(response){
            console.log("response", response);
            let result = response.result
            
 
          await this.getSignatureParameters(result,web3).then( async({r,s,v})=>{
            console.log(r,s,v)
            console.log(r.value,s,v.value)
           const recovered = sigUtil.recoverTypedSignature_v4({
              data: JSON.parse(dataToSign),
              sig: response.result
            })
       await  this.sendSignedTransaction(web3,contract,userAddress, functionSignature, r, s, v).then((res)=>{
         return res
       }).catch(err=> {return ''});
          
          }).catch((err)=>{
              return ''
            });
        
       
           }
          
        }
      );
    };

    sendSignedTransaction = async (web3,contract, userAddress, functionData, r, s, v) => {
      if (web3 && contract) {
        try {
          console.log("owner", userAddress,r,s,v );
          let gasLimit = await contract.methods
              .executeMetaTransaction(userAddress, functionData, r, s, v)
              .estimateGas({ from: userAddress });
              console.log(gasLimit)
          let gasPrice = await web3.eth.getGasPrice();
          console.log(gasPrice)
          let tx = contract.methods
              .executeMetaTransaction(userAddress, functionData, r, s, v)
              .send({
                  from: userAddress
              });

          tx.on("transactionHash", function (hash) {
              console.log(`Transaction hash is ${hash}`);
            }).once("confirmation", function (confirmationNumber, receipt) {
              console.log(receipt);
              return receipt.transactionHash
              //setTransactionHash(receipt.transactionHash);
              //showSuccessMessage("Transaction confirmed on chain");
              //getQuoteFromNetwork();
          });
        } catch (error) {
          return ''
          console.log(error);
         }
      }
   };

   getSignatureParameters = async (signature, web3) => {
     console.log("inside getsign") ;
    if (!web3.utils.isHexStrict(signature)) {
      throw new Error(
        'Given value "'.concat(signature, '" is not a valid hex string.')
      );
    }
    let r = signature.slice(0, 66);
    let s = "0x".concat(signature.slice(66, 130));
    let v:any = "0x".concat(signature.slice(130, 132));
    v = web3.utils.hexToNumber(v);
    if (![27, 28].includes(v)) v += 27;
    return {
      r: r,
      s: s,
      v: v
    };
  };


  async connectMewWallet(){
    this.accountAddress = 'Not connected with wallet';
      const mewConnect = new MEWconnect.Provider({
      infuraId: '387503511cd125d59164319acbc42c7765ece7cb',
      chainId: 80001,
      noUrlCheck: true,
      rpcUrl: 'https://rpc-mumbai.maticvigil.com/v1/387503511cd125d59164319acbc42c7765ece7cb',
      });
      
     // const provider = mewConnect.makeWeb3Provider()
   // console.log("provider ",provider);
      mewConnect.on('disconnected', () => {
      alert('MEWconnect Disconnected')
      })
      try {
       
      const accounts =  await mewConnect.enable();
      const address = accounts[0];
      this.accountAddress = address;
     
    let provider =  new Web3(new Web3.providers.HttpProvider('https://rpc-mumbai.maticvigil.com/v1/387503511cd125d59164319acbc42c7765ece7cb'));
 
     return  provider.eth.getBalance(accounts[0], (e, info)=> {
      if (e) {
        console.log("balance 0",e)
        alert("Somthing went wrong.  Please refresh page")
        
      }
      else if (info) {

        this.mybalance =  web3utils.fromWei(info, 'ether');
        
       
      }
      else {
       console.log("balance is zero")
      }
    })
       
   } catch (e) {
      alert("Somthing went wrong. Please retry again")
       console.log(e);
      }
  }

 async connectFortmaticWallet(){
   try {

   const customNodeOptions = {
    rpcUrl: 'https://rpc-mumbai.matic.today', // your own node url
    chainId: 80001 // chainId of your own node
   }

 const fm:any = new Fortmatic('pk_test_A9E06F750767709D',customNodeOptions);

 var web3 = new Web3(fm.getProvider());
 // let accounts12 = await fm.user.login()
 await  web3.eth.getAccounts((err, accounts) => {
    if (err) throw err;
     this.accountAddress = accounts[0];
    // console.log("account mila kya",this.accountAddress);
    
  });

// await fm.user.getBalances();

  // web3.eth.getAccounts((error, accounts) => {
  //       if(error) return '' ;
  //       this.accountAddress = accounts[0]
  //     console.log("account mila kya",this.accountAddress)
      
  //   });
 
    let   contract = await new web3.eth.Contract(
            abi, this.contractAddress
            
          );
     await contract.methods.balanceOf(this.accountAddress).call().then((balance:any)=>{
         this.mybalance =  web3utils.fromWei(balance, 'ether');
           console.log(this.mybalance)
       });
   
  
    
     return {address:this.accountAddress,balance:this.mybalance}

       } catch (e) {
       console.log("dfsdg",e);

        return ''
     
      }
    
  }


    async connectPortisWallet(){
   try {
    
     const portis = new Portis("4c755999-b110-49a4-ad1c-af876d2889a6", "maticMumbai");
   
   const web3 = new Web3(portis.provider);
      // biconomy.onEvent(biconomy.READY, async() => {
          // Initialize your dapp here like getting user accounts etc
      
    await  web3.eth.getAccounts((error, accounts) => {
        if(error) return error
        this.accountAddress = accounts[0]
      
    });
    let   contract = await new web3.eth.Contract(
            abi, this.contractAddress
            
          );
     await contract.methods.balanceOf(this.accountAddress).call().then((balance:any)=>{
         this.mybalance =  web3utils.fromWei(balance, 'ether');
           
       });
 
    
     return {address:this.accountAddress,balance:this.mybalance}

       } catch (e) {
       console.log(e);

        return ''
     
      }
    
  }

  async connectWallet(){
   try {
    let  provider = window["ethereum"];
      await provider.enable();
     // let kovanProvider = new Web3.providers.HttpProvider("https://kovan.infura.io/v3/d126f392798444609246423b06116c77");
     // setLoadingMessage("Initializing Biconomy ...");
      let matictestProvider = new Web3.providers.HttpProvider(this.maticProvider);
      console.log(matictestProvider)
     const biconomy = new Biconomy(matictestProvider, { apiKey: 'Mg4po8zbw.b0e298c7-8f86-4543-b9c5-64ae297ebc16', debug: true });
      // This web3 instance is used to read normally and write to contract via meta transactions.
   let    web3 = new Web3(biconomy);
      // This web3 instance is used to get user signature from connected wallet
  let    walletWeb3 = new Web3(window.ethereum);
      biconomy.onEvent(biconomy.READY, async() => {
          // Initialize your dapp here like getting user accounts etc
       let   contract = new web3.eth.Contract(
            abi, this.contractAddress
              // config.contract.abi,
              // config.contract.address
          );
       var balance = await contract.methods.balanceOf(provider.selectedAddress).call();
          this.mybalance =  web3utils.fromWei(balance, 'ether');
          // console.log(contract);
          this.accountAddress = provider.selectedAddress
           console.log(provider.selectedAddress);

    //        web3.eth.getBalance(provider.selectedAddress, (e, info)=> {
    //   if (e) {
    //     console.log("balance 0",e)

    //  alert("Somthing went wrong. Please refresh page");
   
    //   }
    //   else if (info) {

    //    this.mybalance =  web3utils.fromWei(info, 'ether');
    //     console.log(this.mybalance)
    //   }
    //   else {
    //    console.log("balance 0 ")
        
    //   }
    // })
    

          // setSelectedAddress(provider.selectedAddress);
          //getQuoteFromNetwork();
          // provider.on("accountsChanged", function (accounts) {
                // setSelectedAddress(accounts[0]);
            // });
      }).onEvent(biconomy.ERROR, (error, message) => {
          // Handle error while initializing mexa
        
      });


// this.accountAddress = 'Not connected with wallet';
// this.mybalance = 0;
// const accounts = await  window.ethereum.request({ method: 'eth_requestAccounts' });
// this.accountAddress = accounts[0];
// let provider =  new Web3(new Web3.providers.HttpProvider('https://rpc-mumbai.maticvigil.com/v1/387503511cd125d59164319acbc42c7765ece7cb'));
   // return  await provider.eth.getBalance(accounts[0], (e, info)=> {
   //    if (e) {
   //      console.log("balance 0",e)

   //   alert("Somthing went wrong. Please refresh page");
   
   //    }
   //    else if (info) {

   //     this.mybalance =  web3utils.fromWei(info, 'ether');
        
   //    }
   //    else {
   //     console.log("balance 0 ")
        
   //    }
   //  })
       } catch (e) {
       console.log(e);

        alert("Somthing went wrong. Please retry again");
     
      }
    
  }

  loginwithaccount({ account }): Observable<{ status: any, message: any, data: any }> {
    const url = `${AppConfig.apiUrl}/account/login`;
    return this.httpClient.post<{ status: any, message: any, data: any }>(url, {  account},)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
            // console.log('on next', val);
          },
          error: error => {
            // console.log('on error', error.message);
          },
          complete: () => {// console.log('complete')
          }
        })
      );
  }

  checkStateVarification(response: any, returnUrl?: string) {
    console.log(response);
    if (!response.Result.IsMobileVerify) {
      console.log('Mobile verification');
      localStorage.setItem('save_token', JSON.stringify(response.Result));
      this.router.navigate(['/signin-mobile'], {queryParams: {type: 'register'}});

    } else if (!response.Result.IsTeamVerify) {
      localStorage.setItem('save_token', JSON.stringify(response.Result));
      this.router.navigate(['/last-step']);
    } else {
      this.loaderService.show();
      this.getUserProfile(response.Result).subscribe((response1: any) => {
        if (response.Status) {
          this.loaderService.hide();
          localStorage.setItem('save_token', JSON.stringify(response.Result));
          localStorage.setItem('user_info', JSON.stringify(response1.Result));
          if (returnUrl) {
            this.router.navigate([returnUrl]);
          } else {
            localStorage.setItem('poup_up', JSON.stringify(3));
            this.router.navigate(['/dashboard/home']);
          }
        }
      }, (error) => {
        this.loaderService.hide();
      });
    }
  }

  isUserExist(item: any) {
    const headersValue = new HttpHeaders().set('email', item.email)
      .set('sId', item.id);
    return this.httpClient.request(
      'get',
      AppConfig.apiUrl + 'users/usercheck',
      {
        headers: headersValue
      }
    );
  }

  getUserProfile(item: any) {
    return this.httpClient.request(
      'get',
      AppConfig.apiUrl + 'account/get-profile/' + item.UserID
    );
  }

  socialLoginIn(item: any, url: string, plateform: string) {
    const headersValue = new HttpHeaders()
      .set('ReferCode', item.userreferCode ? item.userreferCode : '')
      .set('Email', item.email)
      .set('Name', item.name)
      .set(plateform === 'google' ? 'ProviderUserID' : 'FacebookID', item.id);
    return this.httpClient.request(
      'post',
      AppConfig.apiUrl + url,
      {
        headers: headersValue
      }
    );
  }

  checkTokenExpiry(response: any) {
    if (response.TokenExpire) {
      localStorage.removeItem('save_token');
      this.broadcaster.broadcast('setBgOrNot', false);
      this.router.navigate(['/signin']);
    }
  }

  loader(item) {
    const filled = item.LeagueNoOfMembers - item.LeaugeCount;
    const percent = (filled * 100) / item.LeagueNoOfMembers;
    return {
      'width': 100 - percent + '%'
    };
  }


  LeaderBoardLoader(TotalMember,TotalJoined) {
    // const filled = item.LeagueNoOfMembers - item.LeaugeCount;
    const filled = TotalMember - TotalJoined;
    const percent = (filled * 100) / TotalMember;
    return {
      'width': 100 - percent + '%'
    };
  }

  winningBreakupModelOpen(id) {
    $('#' + id).css({
      'bottom': '30px',
      'visibility': 'visible',
      'opacity': '1',
    });
    $('.full_view').addClass('modal-show');
  }

  hideModel() {
    $('.our_modal').each(function () {
      $(this).css({
        'bottom': -$(this).innerHeight(),
        'visibility': 'hidden',
        'opacity': '0',
      });
    });
    $('.full_view').removeClass('modal-show');
  }

}

import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';


export class AppConfig {
  

  public static webUrl = 'https://ragefana.myconecion.com/';
   public static apiUrl = 'https://ragefanapi.myconecion.com/';
  // public static webUrl = 'https://ragefantasy.tk/';
  // public static apiUrl = 'https://api.ragefantasy.tk/';

} 
export const domainType = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" }
];
export const metaTransactionType = [
 { name: "nonce", type: "uint256" },
 { name: "from", type: "address" },
 { name: "functionSignature", type: "bytes" }
];
export let domainData = {
 name: "FanTestToken",
 version: "1",
 chainId: 80001,
 verifyingContract: '0xdf538eC14801624b06214e9C4dE44b3CD555B374'
};

export class CustomValidator {
  static maxDate(yearCount: number): ValidatorFn { // here we have the 'passwords' group
    return (currentControl: AbstractControl): { [key: string]: any } => {
      if (currentControl.value) {
        const year = new Date().getFullYear() - yearCount;
        let month: any = new Date().getMonth() + 1;
        month = month.toString().length === 1 ? '0' + month : month;
        let date1: any = new Date().getDate();
        date1 = date1.toString().length === 1 ? '0' + date1 : date1;
        const max = year + '-' + month + '-' + date1;
        return max >= currentControl.value ? null : {invalidDob: true};
      }
    };
  }

  static checkAccountNo(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.accountNumber.value;
    const confirmPass = group.controls.cAccountNumber.value;
    return pass === confirmPass ? null : {notSame: true};
  }
}




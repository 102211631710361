import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.services';
import { SeriesModule } from './series-rank/series-rank.module';
import { WithoutloginGuard } from './withoutlogin-guard.services';


export const routes: Routes = [  
  {path: '', redirectTo: 'signin-options', pathMatch: 'full'},
  // {path: 'index', loadChildren: './index/index.module#IndexModule'},
  {path: 'signin', loadChildren: './signin/signin.module#SigninModule',canActivate:[WithoutloginGuard]},
   {path: 'connectwallet', loadChildren: './addwallet/addwallet.module#AddwalletModule', canActivate:[WithoutloginGuard]},
   {path: 'signin-options', loadChildren: './signin-options/signin-options.module#SigninOptionsModule', canActivate:[WithoutloginGuard]},
  {path: 'signin-mobile', loadChildren: './signin-mobile/signin-mobile.module#SigninMobileModule'},
  {path: 'signup', loadChildren: './signup/signup.module#SignupModule' , canActivate:[WithoutloginGuard]},  
  {path: 'last-step', loadChildren: './last-step/last-step.module#LastStepModule'},
  {path: 'enter-otp', loadChildren: './enter-otp/enter-otp.module#EnterOtpModule'},
  {path: 'forget-password', loadChildren: './forget-password/forget-password.module#ForgetPasswordModule'},
  {path: 'password-change', loadChildren: './password-change/password-change.module#PasswordChangeModule'},
  {path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule',canActivate:[AuthGuard]},
  {path: 'header', loadChildren: './header/header.module#HeaderModule',canActivate:[AuthGuard]},
  {path: 'webview/:path', loadChildren: './webview/webview.module#WebviewModule',canActivate:[AuthGuard]},
  {path: 'change-password', loadChildren: './change-password/change-password.module#ChangePasswordModule',canActivate:[AuthGuard]},
  {path: 'support', loadChildren: './support/support.module#SupportModule',canActivate:[AuthGuard]},
  {path: 'contest-code', loadChildren: './contest-code/contest-code.module#ContestCodeModule',canActivate:[AuthGuard]},
  {path: 'add-cash', loadChildren: './add-cash/add-cash.module#AddCashModule',canActivate:[AuthGuard]},
  {path: 'transaction-history', loadChildren: './transaction-history/transaction-history.module#TransactionHistoryModule',canActivate:[AuthGuard]},
  {path: 'kyc', loadChildren: './kyc/kyc.module#KycModule',canActivate:[AuthGuard]},
  {path: 'my-referral', loadChildren: './my-referral/my-referral.module#MyReferralModule',canActivate:[AuthGuard]},
  {path: 'refer-n-earn', loadChildren: './refer-n-earn/refer-n-earn.module#ReferNEarnModule',canActivate:[AuthGuard]},
  {path: 'withdrawal', loadChildren: './withdrawal/withdrawal.module#WithdrawalModule',canActivate:[AuthGuard]},
  {path: 'point-dashboard/:matchid', loadChildren: './point-dashboard/point-dashboard.module#PointDashboardModule',canActivate:[AuthGuard]},
  {path: 'quiz-start/:matchid', loadChildren: './quiz-play/quiz-play.module#QuizPlayModule',canActivate:[AuthGuard]},
  
  {path: 'favourite-leagues', loadChildren: './favourite-leagues/favourite-leagues.module#FavouriteLeaguesModule',canActivate:[AuthGuard]},

  // {path: 'user-profile', loadChildren: './profile/profile.module#ProfileModule'},/
  {path: 'view-player-stats/:matchid', loadChildren: './player-stats/player-stats.module#PlayerStatsModule',canActivate:[AuthGuard]},
  {path: 'view-player-stats/:matchid/:leagueid', loadChildren: './player-stats/player-stats.module#PlayerStatsModule',canActivate:[AuthGuard]},
  {path: 'player-history/:playerid/:team', loadChildren: './player-history/player-history.module#PlayerHistoryModule',canActivate:[AuthGuard]},
  {path: 'leaderborad-type', loadChildren: './leaderborad-type/leaderborad-type-module#LeaderboardTypeModule',canActivate:[AuthGuard]},
  {path: 'series-rank/:id/:tourname', loadChildren: './series-rank/series-rank.module#SeriesModule',canActivate:[AuthGuard]},
  {path: 'full-score/:matchId', loadChildren: './full-scorecard/full-scorecard.module#FullSocreCardModule',canActivate:[AuthGuard]},
 
  {path: '**', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule',canActivate:[AuthGuard]}
];

@NgModule({  
  
  imports: [ 
    
    RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
